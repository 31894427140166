@import '../../assets/styling/util'

.title, .description
  transition: color $theme_transition_time ease-out

.title
  font-family: sans-serif
  @include themed_variables_section
    color: themed('landing-title-color')

.description
  font-family: 'Courier New', sans-serif
  @include themed_variables_section
    color: themed('landing-description-color')