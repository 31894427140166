@import '../../assets/styling/util'

.dropdown
  transition: background-color $theme_transition_time ease-out, border 0.2s ease-out
  top: 58px
  width: 200px
  transform: translateX(-45%)
  border-radius: 8px
  padding: 0.5rem
  overflow: hidden
  @include themed_variables_section
    border: 1px solid themed('border-color')
    background-color: themed('dropdown-color')