@import '../../assets/styling/util'


.startToggle
  display: none

  &:checked ~ div > form
    width: 382px

    > label
      visibility: hidden
      opacity: 0
      transform: scale(0.7)

    > .usernameGroup
      visibility: visible
      opacity: 1

      > .inputLabel > .inputUnderline
        width: 100%

  ~ div > form
    @include themed_variables_section
      background-color: themed('auth-box-background-color')

    > label
      transition: 0.2s
      @include themed_variables_section
        color: themed('auth-box-start-label-color')

    .dataGroup
      width: 100%
      height: 100%
      visibility: hidden
      opacity: 0
      padding: 12px 15px
      box-sizing: border-box
      transition: 0.2s 0.2s
      position: absolute
      top: 0

      > label
        width: calc(100% - 40px)
        font-size: 17px
        font-weight: 800
        font-family: Consolas, monospace
        @include themed_variables_section
          color: themed('auth-box-question-color')

      .dataInput
        font-size: 20px
        font-family: inherit
        width: 100%
        height: 90%
        padding: 15px !important
        border: 0
        outline: 0
        background: transparent
        box-sizing: border-box
        cursor: pointer
        &:focus ~ .inputUnderline,
        &:not(:placeholder-shown) ~ .inputUnderline,
        &:focus ~ .submitArrow,
        &:not(:placeholder-shown) ~ .submitArrow
          transition: 0.3s
        &:focus
          cursor: initial
        @include themed_variables_section
          color: themed('auth-box-input-color')
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active
          @include themed_variables_section
            -webkit-box-shadow: 0 0 0 30px inset themed('auth-box-background-color')
            -webkit-text-fill-color: #2e2e2b
        &:focus ~ .inputHint,
        &:not(:placeholder-shown) ~ .inputHint
          font-size: 13px
          top: -7px
          transform: translateY(0)
          @include themed_variables_section
            color: themed('auth-box-question-inactive-color')

      .inputHint
        position: absolute
        top: 50%
        transform: translateY(-50%)
        transition: 0.2s
        pointer-events: none

      .submitArrow
        position: absolute
        right: -40px
        height: 100%
        width: 40px
        cursor: default
        display: flex
        align-items: center
        justify-content: flex-end
        pointer-events: none
        @include themed_variables_section
          color: themed('auth-box-accent-color')

      .inputUnderline
        position: absolute
        width: 0
        height: 3px
        background-color: currentColor
        transition: width 1s 0.2s ease-out
        @include themed_variables_section
          color: themed('auth-box-accent-color')