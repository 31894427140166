@import '../../assets/styling/util'

.menuItem
  background-color: inherit
  margin-top: 3px
  height: 50px
  display: flex
  align-items: center
  border-radius: 8px
  padding: 0.5rem
  transition: filter $theme_transition_time ease-out, color 0.2s ease-out
  @include themed_variables_section
    color: themed('text-color')

.menuItem:hover
  @include themed_variables_section
    filter: themed('hover-filter')

.iconRight
  margin-left: auto