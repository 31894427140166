@import '../../assets/styling/util'

.link
  text-decoration: none
  -webkit-transition: color $link_animation_time ease-out
  transition: color $link_animation_time ease-out
  &:after
    content: ""
    display: block
    width: 0
    height: 3px
    -webkit-transition: width $link_animation_time ease-out
    transition: width $link_animation_time ease-out
  &:hover:after
    width: 100%
  @include themed_variables_section
    color: themed('link-color')
    &:after
      background-color: themed('link-underline-color')
    &:hover
      color: themed('link-hover-color')