@import '../../assets/styling/util'

$f: $loader-size
$h: $loader-size / 2

.loader
  & > span
    position: relative
    width: $f
    height: $f
    transform: rotate(45deg)
    &::before
      content: ''
      position: absolute
      width: $h
      height: $h
      left: 0
      top: -$h
      animation: trail-animation $loader-animation-time * 2 ease infinite
    &::after
      content: ''
      position: absolute
      width: $h
      height: $h
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15)
      opacity: 35%
      animation: roll-animation $loader-animation-time ease infinite
    @keyframes roll-animation
      0%
        transform: translate(0, 0) rotateX(0) rotateY(0)
      25%
        transform: translate(100%, 0) rotateX(0) rotateY(180deg)
      50%
        transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg)
      75%
        transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg)
      100%
        transform: translate(0, 0) rotateX(0) rotateY(360deg)
    @keyframes trail-animation
      0%
        box-shadow: 0 $h transparent, $h $h transparent, $h $f transparent, 0 $f transparent
      12%
        box-shadow: 0 $h #fff9f2, $h $h transparent, $h $f transparent, 0 $f transparent
      25%
        box-shadow: 0 $h #fff9f2, $h $h #fff9f2, $h $f transparent, 0 $f transparent
      37%
        box-shadow: 0 $h #fff9f2, $h $h #fff9f2, $h $f #fff9f2, 0 $f transparent
      50%
        box-shadow: 0 $h #fff9f2, $h $h #fff9f2, $h $f #fff9f2, 0 $f #fff9f2
      62%
        box-shadow: 0 $h transparent, $h $h #fff9f2, $h $f #fff9f2, 0 $f #fff9f2
      75%
        box-shadow: 0 $h transparent, $h $h transparent, $h $f #fff9f2, 0 $f #fff9f2
      87%
        box-shadow: 0 $h transparent, $h $h transparent, $h $f transparent, 0 $f #fff9f2
      100%
        box-shadow: 0 $h transparent, $h $h transparent, $h $f transparent, 0 $f transparent
  @include themed_variables_section
    background: themed('loading-screen-background')
    & > span::after
      background-color: themed('loading-screen-animation-color')
