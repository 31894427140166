// Config

$nav_size: 65px
$loader-size: 20vw
$loader-animation-time: 2s
$theme_transition_time: 0.3s
$link_animation_time: 0.2s

// Styles

html
  height: 100vh
  overflow: hidden

body
  height: 100vh
  overflow: hidden
  font-size: larger
  letter-spacing: 1px
  font-family: Consolas, Impact, 'Courier New', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.mapboxgl-ctrl-attrib, .mapboxgl-ctrl-logo
  display: none !important

.mapboxgl-ctrl-top-left
  margin-top: 70px