@import '../../assets/styling/util'

.themeSwitch
  width: $nav_size * 1.3

.themeSwitchBackground
  width: 100%
  height: $nav_size * 0.45
  cursor: pointer
  transition: background-color $theme_transition_time ease-out, filter $theme_transition_time ease-out
  &:hover
    filter: brightness(1.1)
  @include themed_variables_section
    background-color: themed('theme-switch-background-color')

.themeSwitchIcon
  transition: margin-left 0.2s ease-out
  @include themed_variables_section
    background: themed('theme-switch-icon-background')

:global(#theme-switch:checked) + label > span
  margin-left: calc(100% - $nav_size * 0.45 + 2px)