@import './global'
@import './themes'

@tailwind base
@tailwind components
@tailwind utilities

@mixin themed_variables_section
  @each $theme in map-keys($themes)
    :global(.theme-#{$theme}) &
      $current-theme: $theme !global
      @content
      $current-theme: null !global

@function themed($key)
  @return map-get(map-get($themes, $current-theme), '#{$key}')