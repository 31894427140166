@import '../../assets/styling/util'

.navButton
  width: $nav_size * 0.7
  height: $nav_size * 0.7
  transition: background-color $theme_transition_time ease-out, filter $theme_transition_time ease-out
  @include themed_variables_section
    background-color: themed('button-color')
    color: themed('text-color')
    &:hover
      filter: themed('button-hover-filter')